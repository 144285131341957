




































































































































import { Component, Provide, Vue } from "vue-property-decorator";
import pcDemo from '@/components/pcDemo.vue'
import { Picker, Search } from 'vant';
import Compressor from 'compressorjs';
import { CommonListRes, Page  } from "@/api/common";
import RegisterDialog from "@/components/RegisterSuccessDialog.vue";
import { Toast } from "vant";
import SendButton from "@/components/SendButton.vue";
import { Repair} from "@/api/repair";
import RobotBoard from "@/components/RobotBoard.vue";
import { globalData } from "@/utils/config";
import {
  getRobotList,
  GetRobotListReq,
  Robot
} from "@/api/robot";
interface checkItem {
  title: string,
  fileList: any[],
  content: string
}
@Component({
  components: {
    RegisterDialog,
    SendButton,
    RobotBoard,
    [Picker.name]: Picker,
    [Search.name]: Search,
    pcDemo
  },
})
export default class Index extends Vue {
  private robotName:string = '';
  private repair!: Repair;
  private picPref: string = globalData.exportFileUrl + 'repair/';
  private showImg:boolean = false
  private showImgType: number = 0;
  private images: string[] = []
  private indexImg: number = 0;
  private adminData = {
    admin: '',
    date: '',
    sign: ''
  }
  private checkData: checkItem[] = []
  created() {
    console.log("register created");
    console.log("checkData初", this.checkData)
    let repairDetail = this.$route.params.repairDetail;
    console.log('faultdetail', repairDetail)
    if (repairDetail != undefined && repairDetail != null && repairDetail.length != 0) {
      this.repair = JSON.parse(repairDetail)
      this.robotName = this.repair.robotEntity.name
      if(this.repair.adminInfo != null) {
        this.adminData = JSON.parse(this.repair.adminInfo)
        this.adminData.sign = this.picPref + this.repair.robotId + "/" + this.adminData.sign
      }
      let entryList = JSON.parse(this.repair.entryList)
      for(let i=0; i < entryList.length; i++) {
        this.setCheckData(i + 1, entryList[i])
      }
    }
  }
   private setCheckData (index:number, JsonObject: any ) {
    let checkItem1: checkItem = {
        title: '',
        fileList: [],
        content: ''
    }
    checkItem1.title = this.$t('question').toString() + index + ":"
    checkItem1.content = JsonObject.content
    if(JsonObject.img != null && JsonObject.img.length > 0) {
      let phyImg:[] = JSON.parse(JsonObject.img)
      phyImg.forEach(element => {
        checkItem1.fileList.push(this.picPref + this.repair.robotId + "/" + element)
      });
    }
    this.checkData.push(checkItem1)
 }
  private imageClick (fileList:string[], showImgType: number) {
    this.showImg = true
    this.showImgType = showImgType
    this.images = fileList
 }
 private onChangeImg (newIndex: any) {
   this.indexImg = newIndex;
 }
  private back() {
    this.$router.go(-1);
  }
}
